import "keen-slider/keen-slider.min.css";
import "./src/static/styles/app.scss";
import "aos/dist/aos.css";
import AOS from "aos";

export const onClientEntry = () => {
  AOS.init({
    duration: 1200,
  });
};

export const onRouteUpdate = () => {
  AOS.refresh();
};